import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ScrollMemory from 'react-router-scroll-memory';

import App from 'app/App';
import theme from 'services/theme';
import { AppState } from 'app/AppContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { RAZZLE_USE_SW, RAZZLE_ENV } = window.env;
const useSW = RAZZLE_ENV === 'production' || RAZZLE_USE_SW === '1';
// Check that service workers are supported
if (useSW && 'serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then((registration) => {
        console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}

ReactDOM.render(
  <BrowserRouter>
    <AppState>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ScrollMemory />
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AppState>
  </BrowserRouter>,
  document.querySelector('#root')
);
