import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.grey[100]
  }
}));

export default function InfoBox({ title }) {
  const classes = useStyles();
  return (
    <Box className={classes.box} p={2}>
      <Typography>{title}</Typography>
    </Box>
  );
}
