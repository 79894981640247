import useMediaQuery from '@material-ui/core/useMediaQuery';

export const layoutSizes = {
  sm: 0,
  md: 1,
  lg: 2
};

const maxWidths = {
  sm: 600,
  md: 1024
};

export function useLayoutSize() {
  // const sm = useMediaQuery(theme => theme.breakpoints.between(0, 600))
  const md = useMediaQuery((theme) => theme.breakpoints.between(maxWidths.sm, maxWidths.md));
  const lg = useMediaQuery((theme) => theme.breakpoints.up(maxWidths.md + 1));

  if (md) {
    return layoutSizes.md;
  }
  if (lg) {
    return layoutSizes.lg;
  }
  return layoutSizes.sm;
}
