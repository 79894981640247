import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: 'flex',
    height: '100%'
  },
  center: {
    margin: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

/**
 * Renders a loading spinner
 * @module Spinner
 * */
export default function Spinner({ loading }) {
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <div className={classes.progressContainer}>
      <div className={classes.center}>
        <CircularProgress size={50} />
      </div>
    </div>
  );
}
