import LearningBackground from '../images/mother-giving-baby-boy-massage.jpg';
const { RAZZLE_SIGN_IN_URL } = window.env;
import HomeColor from '../images/home-color.svg';
import DashboardColor from '../images/dashboard-color.svg';
import CoachingColor from '../images/coaching-color.svg';
import CommunityColor from '../images/community-color.svg';
import ResourcesColor from '../images/resources-color.svg';

const routes = [
  // account
  {
    route: /^\/$/,
    layout: 'account',
    auth: false
  },
  {
    route: /^\/signup$/,
    layout: 'account',
    auth: false
  },
  {
    route: /^\/signin$/,
    layout: 'signin',
    auth: false
  },
  {
    route: /^\/signup\/confirm$/,
    layout: 'account',
    auth: false
  },
  {
    route: /^\/recover-password$/,
    layout: 'account',
    auth: false
  },
  {
    route: /^\/recover-password\/confirm$/,
    layout: 'account',
    auth: false
  },

  // browse
  {
    route: /^\/browse$/,
    title: 'Browse',
    layout: 'root',
    color: 'linear-gradient(0deg, rgba(255, 255, 255, 0), rgb(29, 169, 189))',
    auth: true,
    // position: 'fixed'
  },
  {
    route: /^\/courses$/,
    title: 'Courses',
    layout: 'root',
    backHref: '/browse',
    color: '#0DB453',
    auth: true
  },
  {
    route: /^\/courses\/\d$/,
    title: 'Course',
    layout: 'root',
    backHref: '/learning',
    color: '#00819d',
    auth: true
  },
  {
    route: /^\/modules$/,
    title: 'Modules',
    layout: 'child',
    backHref: '/browse',
    auth: true
  },
  {
    route: /^\/categories$/,
    title: 'Categories',
    layout: 'child',
    backHref: '/browse',
    auth: true
  },
  {
    route: /^\/categories\/s*/,
    title: 'Category',
    layout: 'child',
    backHref: '/browse',
    auth: true
  },
  // profile
  {
    route: /^\/profile$/,
    title: 'Profile',
    layout: 'root',
    color: 'linear-gradient(0deg, rgba(255, 255, 255, 0), rgb(29, 169, 189))',
    auth: true,
    // position: 'fixed'
  },
  // onboarding
  {
    route: /^\/onboarding$/,
    auth: true
  },
  {
    route: /^\/settings$/,
    title: 'Settings',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/preferences$/,
    title: 'Platform Preferences',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/general$/,
    title: 'Profile Information',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/demographics$/,
    title: 'Demographic Information',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/professionalInterest$/,
    title: 'Professional Interest Information',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/workplace$/,
    title: 'Workplace Information',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/career$/,
    title: 'Professional Details',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/settings\/picture$/,
    title: 'Change your photo',
    layout: 'child',
    auth: true,
    backHref: '/profile'
  },
  {
    route: /^\/home$/,
    title: 'Home',
    layout: 'root',
    color: 'rgba(29, 169, 189, 0.85)',
    auth: true,
    // position: 'fixed',
    //backgroundImage: LearningBackground,
    tab: 0
  },
  {
    route: /^\/dashboard$/,
    title: 'Dashboard',
    layout: 'root',
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    auth: true,
    // position: 'fixed',
    tab: 1
  },
  // {
  //   route: /^\/learning$/,
  //   title: "Learning",
  //   // layout: "root",
  //   color: "linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)",
  //   auth: true,
  //   tab: 1,
  // },
  {
    route: /^\/notifications$/,
    title: 'Notifications',
    layout: 'root',
    color: '#144CC7',
    auth: true
  },
  {
    route: /^\/learning$/,
    title: 'Learning',
    layout: 'root',
    color: 'transparent',
    //backgroundImage: LearningBackground,
    // position: 'fixed',
    auth: true,
    // tab: 0,
    icon: <img className="bottom-nav-icon" src={HomeColor} alt="home" />
  },
  {
    route: /^\/learning\/s*/,
    title: 'Learning',
    layout: 'child',
    color: 'transparent',
    //backgroundImage: LearningBackground,
    // position: 'fixed',
    auth: true,
    // tab: 0,
    icon: <img className="bottom-nav-icon" src={CommunityColor} alt="home" />
  },
  {
    route: /^\/learning\/s*\s*/,
    title: 'Learning',
    // layout: "child",
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    //backgroundImage: LearningBackground,
    // position: 'fixed',
    auth: true,
    // tab: 0,
    icon: <img className="bottom-nav-icon" src={CommunityColor} alt="home" />
  },
  {
    route: /^\/enroll$/,
    title: 'Enroll',
    layout: 'root',
    color: 'rgba(29, 169, 189, 0.85)',
    // position: 'fixed',
    auth: true,
    tab: 0
  },
  {
    route: /^\/enroll\/s*/,
    title: 'Enroll',
    layout: 'child',
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    position: 'fixed',
    auth: true,
    tab: 0
  },
  {
    route: /^\/enroll\/s*\s*/,
    title: 'Enroll',
    // layout: "child",
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    // position: 'fixed',
    auth: true
  },
  {
    route: /^\/list$/,
    title: 'List',
    layout: 'root',
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    backgroundImage: LearningBackground,
    auth: true
  },
  {
    route: /^\/community$/,
    title: 'Community',
    layout: 'root',
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    auth: true,
    tab: 3,
    // position: 'fixed'
  },
  {
    route: /^\/community\/s*/,
    title: 'Community',
    layout: 'root',
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    auth: true,
    tab: 3,
    icon: <img className="bottom-nav-icon" src={HomeColor} alt="home" />
  },
  {
    route: /^\/library\/s*/,
    title: 'Library',
    layout: 'root',
    auth: true,
    tab: 4,
  },
  {
    route: /^\/recent$/,
    title: 'Recent',
    layout: 'child',
    backHref: '/home',
    auth: true
  },
  {
    route: /^\/recent\/\d$/,
    title: 'Recent',
    layout: 'child',
    backHref: '/home',
    auth: true
  },
  {
    route: /^\/progress$/,
    title: 'My Progress',
    layout: 'root',
    auth: true,
    tab: 2
  },
  {
    route: /^\/library$/,
    title: 'Library',
    layout: 'root',
    auth: true,
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    tab: 4,
    // position: 'fixed'
  },
  {
    route: /^\/shared-library$/,
    title: 'Library',
    layout: 'root',
    auth: true,
    color: 'linear-gradient(0deg, rgb(255, 255, 255, 0), #1DA9BD)',
    tab: 4,
    // position: 'fixed'
  },
  {
    route: /^\/coaching$/,
    title: 'Coaching',
    layout: 'root',
    auth: true,
    color: 'rgba(29, 169, 189, 0.85)',
    tab: 2,
    // position: 'fixed'
  },
  {
    route: /^\/shared-library\/s*/,
    title: 'Library',
    layout: 'child',
    backHref: '/library',
    noHeader: true,
    auth: true,
    tab: 4,
    // position: 'fixed'
  },
  {
    route: /^\/coaching\/s*/,
    title: 'Coaching',
    layout: 'root',
    backHref: '/coaching',
    // noHeader: true,
    auth: true,
    // position: 'fixed',
    tab: 2
  }
];

const fallbackRoute = {
  route: RAZZLE_SIGN_IN_URL,
  layout: 'empty',
  auth: false
};

export function mapRoute(pathname) {
  for (let i = 0; i < routes.length; i++) {
    const r = routes[i];

    if (r.route.test(pathname)) {
      return r;
    }
  }
  return fallbackRoute;
}
