import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  workPlaceOptions,
  ageGroupOptions,
  stateOptions,
  countryOptions
} from 'services/profileOptions';
import Spinner from 'common/Spinner';
import DropDown from 'common/FieldDropDown';
import DatePickerField from 'common/FieldDatePicker';
import PhoneNumberField from 'common/FieldPhoneNumber';
import SubmitButtonForm from 'core/profile/SubmitButtonForm';
import InfoBox from 'core/profile/InfoBox';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useOnboardingData } from 'hooks/useOnboardingData';
import { OnboardingContext } from '../../providers/onboarding-provider';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.main,
    position: 'relative',
    paddingBottom: 60
  },
  paper: {
    marginBottom: theme.spacing(2),
    borderBottom: '5px solid lightblue',
    boxShadow: 'none',
    overflow: 'hidden', // fix horiz scroller on grid with spacing={2}
    marginBottom: 0,
    paddingBottom: 16
  },
  formContainer: {
    padding: theme.spacing(2)
  },
  switchHeader: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16
  },
  switchInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  saveButton: {
    position: 'relative',
    top: 16,
    width: '90%',
    left: '5%'
  }
}));

const StepThree = () => {
  const classes = useStyles();

  const { setStepThreeData } = useContext(OnboardingContext);
  const [initialValues, setInitialValues] = useState({
    dob: null, // not included in API
    name: '',
    addr1: '',
    addr2: '',
    city: '',
    state: '',
    zip: '',
    primaryPhone: ''
  });

  const [switchState, setSwitchState] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    checkedI: false,
    checkedJ: false,
    checkedK: false,
    checkedL: false,
    checkedM: false,
    checkedN: false,
    checkedO: false,
    checkedP: false,
    checkedQ: false,
    checkedR: false,
    checkedS: false,
    checkedT: false,
    checkedU: false,
    checkedV: false,
    checkedW: false,
    checkedX: false,
    checkedY: false,
    checkedZ: false,
    checkedAA: false,
    checkedBB: false,
    checkedCC: false,
    checkedDD: false,
    checkedEE: false,
    checkedFF: false,
    checkedGG: false
  });

  const handleSwitchChange = (event, key) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked
    });
    handleChange(event, key, 'switch');
  };

  const [values, setValues] = useState({
    primaryLang: ''
  });

  const handleChange = (event, key, ele) => {
    let oldState = values;
    if ((key === 'dob') | (key === 'empPhone')) {
      oldState[key] = event;
    } else if (ele === 'switch') {
      oldState[key] = event.target.checked;
    } else {
      oldState[key] = event.target.value;
    }
    setValues(oldState);
    setStepThreeData(oldState);
  };

  const renderSwitch = () => (
    <Paper square className={classes.paper}>
      <div className={classes.switchHeader}>
        <Typography variant="h6">{'Professional Interests'}</Typography>
        <br />

        <p className={classes.text}>
          What would you like to learn more about? (Select all that apply)
          <br />
        </p>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Adult/child interactions</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedA}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Adult/child interactions');
            }}
            name="checkedA"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Advocacy</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedB}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Advocacy');
            }}
            name="checkedB"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>(Child assessment)</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedC}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Child assessment');
            }}
            name="checkedC"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Child development</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedD}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Child development');
            }}
            name="checkedD"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Children with special needs</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedE}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Children with special needs');
            }}
            name="checkedE"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Coaching</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedF}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Coaching');
            }}
            name="checkedF"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Community engagement</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedG}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Community engagement');
            }}
            name="checkedG"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Culturally responsive practices</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedH}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Culturally responsive practices');
            }}
            name="checkedH"
            color="primary"
          />
        </div>
      </div>

      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Curriculum planning and implementation</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedI}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Curriculum planning and implementation');
            }}
            name="checkedI"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Daily planning</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedJ}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Daily planning');
            }}
            name="checkedJ"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Developmental Screening</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedK}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Developmental Screening');
            }}
            name="checkedK"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>DRDP</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedL}
            onChange={(event, key) => {
              handleSwitchChange(event, 'DRDP');
            }}
            name="checkedL"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Dual language learners</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedM}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Dual language learners');
            }}
            name="checkedM"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Early literacy/reading and writing skills</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedN}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Early literacy/reading and writing skills');
            }}
            name="checkedN"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Early math skills</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedO}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Early math skills');
            }}
            name="checkedO"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Family partnerships</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedP}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Family partnerships');
            }}
            name="checkedP"
            color="primary"
          />
        </div>
      </div>

      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Foundations and curriculum frameworks</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedQ}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Foundations and curriculum frameworks');
            }}
            name="checkedQ"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Health</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedR}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Health');
            }}
            name="checkedR"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Home-based care</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedS}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Home-based care');
            }}
            name="checkedS"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Inclusion</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedT}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Inclusion');
            }}
            name="checkedT"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Language development</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedU}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Language development');
            }}
            name="checkedU"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Leadership</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedV}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Leadership');
            }}
            name="checkedV"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Learning environments</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedW}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Learning environments');
            }}
            name="checkedW"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Mental Health</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedX}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Mental Health');
            }}
            name="checkedX"
            color="primary"
          />
        </div>
      </div>

      {/* ////// */}

      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Nutrition</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedY}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Nutrition');
            }}
            name="checkedY"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Observational assessments</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedZ}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Observational assessments');
            }}
            name="checkedZ"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Professionalism</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedAA}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Professionalism');
            }}
            name="checkedAA"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Program administration</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedBB}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Program administration');
            }}
            name="checkedBB"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Safety</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedCC}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Safety');
            }}
            name="checkedCC"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Self-care/stress management</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedDD}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Self-care/stress management');
            }}
            name="checkedDD"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Social and emotional development</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedEE}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Social and emotional development');
            }}
            name="checkedEE"
            color="primary"
          />
        </div>
      </div>
      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>STEM/STEAM</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedFF}
            onChange={(event, key) => {
              handleSwitchChange(event, 'STEM/STEAM');
            }}
            name="checkedFF"
            color="primary"
          />
        </div>
      </div>

      <div className={classes.switchInfo}>
        <div className={classes.switch}>
          <p>Trauma-Informed Practices</p>
        </div>
        <div>
          <Switch
            checked={switchState.checkedGG}
            onChange={(event, key) => {
              handleSwitchChange(event, 'Trauma-Informed Practices');
            }}
            name="checkedGG"
            color="primary"
          />
        </div>
      </div>
    </Paper>
  );

  return (
    <div className={classes.container}>
      {/* {renderContactInfoForm()}
      {renderAdditionalInfo()} */}
      {renderSwitch()}
      <Button
        variant="contained"
        color="primary"
        className={classes.saveButton}
        // onClick={handleClick}
      >
        Save
      </Button>
    </div>
  );
};

export default StepThree;
