import { successResponse, errorResponse, validationErrorResponse } from 'services/response-helper';

async function processResponse(response) {
  const data = await response.json();

  if (response.ok) {
    return successResponse(data);
  }

  if (response.status === 422) {
    return validationErrorResponse(data);
  }

  return errorResponse(`Request failed with status: ${response.status}`);
}

function getHeaders(token) {
  const headers = {
    'Content-Type': 'application/json'
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function getHeadersPostRegistration(token) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function getHeadersPostForm(token) {
  const headers = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

export async function get(url, token, attemptsLeft) {
  try {
    const response = await fetch(url, {
      headers: getHeaders(token)
    });

    return processResponse(response);
  } catch (error) {
    // setTimeout(() => {
    //   if (attemptsLeft === 1) {
    //     reject(error);
    //     return;
    //   }
    //   get(url,token,  attemptsLeft - 1).then(resolve, reject);
    // }, 1500);

    return errorResponse(error.message);
  }
}

export async function post(url, body, token) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify({ body })
    });

    return processResponse(response);
  } catch (error) {
    return errorResponse(error.message);
  }
}

export async function postRegistration(url, body, token) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeadersPostRegistration(token),
      body: JSON.stringify(body)
    });

    return processResponse(response);
  } catch (error) {
    return errorResponse(error.message);
  }
}

export async function put(url, body, token) {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(body)
    });

    return processResponse(response);
  } catch (error) {
    return errorResponse(error.message);
  }
}

export async function postForm(url, body, token) {
  try {
    const formData = new FormData();
    for (const k in body) {
      formData.append(k, body[k]);
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeadersPostForm(token),
      body: formData
    });

    return processResponse(response);
  } catch (error) {
    return errorResponse(error.message);
  }
}