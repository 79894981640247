export function errorResponse(message) {
  return {
    success: false,
    data: { message }
  };
}

export function successResponse(data) {
  return {
    success: true,
    data
  };
}

export function validationErrorResponse({ detail }) {
  const message = detail.reduce((acc, cur) => {
    const { msg, loc } = cur;
    const key = loc[loc.length - 1];
    const text = `${key}: ${msg}`;
    if (!acc) {
      return text;
    }
    return `${acc}, ${text}`;
  }, '');

  return errorResponse(message);
}
