export function getEarlyWinsId(userData) {
  if (!userData) {
    return -1;
  }

  const ewId = userData['custom:earlyWinsUserID'];
  if (!ewId) {
    return -1;
  }

  return parseInt(ewId);
}

export function parseUser(user) {
  try {
    const userName = user.username;
    const attributes = user.attributes;
    const accessToken = user.signInUserSession.accessToken.jwtToken;
    const idToken = user.signInUserSession.idToken.jwtToken;
    const refreshToken = user.signInUserSession.refreshToken.token;
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    const ewId = getEarlyWinsId(attributes);
    const userData = {
      userName,
      attributes,
      accessToken,
      idToken,
      refreshToken,
      groups,
      ewId
    };
    return userData;
  } catch (error) {
    return null;
  }
}

export function isUserInGroup(user, group) {
  if (!user || !user.groups || !user.groups.length) {
    return false;
  }
  for (let i = 0; i < user.groups.length; i++) {
    if (user.groups[i] === group) {
      return true;
    }
  }
  return false;
}
