import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { currentAuthenticatedUser } from 'services/auth'
import { parseUser } from 'services/user'
import { mapRoute } from 'services/routing'
import { get } from "services/http";

const { RAZZLE_SIGN_IN_URL } = window.env;

export const AppContext = React.createContext()

export function AppState({ children }) {
  const history = useHistory()
  const location = useLocation()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState({})
  const { RAZZLE_API_HOST } = window.env;

  useEffect(() => {
    const hydrate = async () => {
      const page = mapRoute(location.pathname)
      const userData = await currentAuthenticatedUser()
      const parsedUser = parseUser(userData)
      setUser(parsedUser)

      if (page.auth) {
        if (!userData || !userData.username) {
          if (location.pathname === '/signin') {
            history.push("/signin")
          }
          if (location.pathname === '/signup') {
            history.push("/signup")
          } else {
            history.push(RAZZLE_SIGN_IN_URL)
          }
          return
        }
      } else {
        if (userData) {
          const url = `${RAZZLE_API_HOST}/getUserResponse/${parsedUser.ewId}`;
          const userProfile = await get(url, parsedUser.idToken);

          if (userProfile && userProfile ?.success) {
            if (userProfile ?.data.results ?.user.userProfile["CSPP"] !== "finished") {
              history.push("/onboarding");
            } else {
              history.push("/home")
            }
          }
        }
      }
      // else {
      //   history.push("/home")
      //   return
      // }
      // else {
      //   const userData = await currentAuthenticatedUser();
      //   // if (signedInUser.user === null) {
      //   //   history.push("/onboarding")
      //   // } else {
      //     if (userData || userData ?.username) {
      //       history.push("/home")
      //       return
      //   }
      // }

      setLoaded(true)
    }

    if (!loaded) {
      hydrate()
    }
  }, [loaded, location, history]);

  const context = {
    user,
    setUser,
    clearUser: () => setUser({}),
    platformPreferences: [],
  }

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  )
}