import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

export default function FieldPhoneNumber({ field, form, ...other }) {
  const { name, value, autoComplete, onKeyDown } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const touchedField = touched[name];
  const currentError = errors[name];
  const hasError = Boolean(touchedField && currentError);

  const handleChange = (phone) => {
    setFieldTouched(name, Boolean(phone));
    setFieldValue(field.name, phone, true);
  };

  function backspace(e) {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      if (e.target.selectionStart == 2 && value.split('')[1] === '1') e.preventDefault();
    }
  }

  const checkVals = () => {
    if (value.split('')[1] !== '1') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MuiPhoneNumber
      disableCountryCode
      variant="outlined"
      fullWidth
      value={value}
      name={name}
      helperText={hasError ? currentError : ''}
      error={hasError}
      onChange={handleChange}
      disableDropdown
      defaultCountry={'us'}
      placeholder="(555) 555-5555"
      disableAreaCodes
      type="text"
      autoComplete="off"
      onKeyDown={(event) => backspace(event)}
      {...other}
      onlyCountries={['us']}
    />
  );
}
