import React from 'react';
import { useOnboardingData } from '../hooks/useOnboardingData';

export const OnboardingContext = React.createContext({
  setOnboarding: () => {},
  stepOneData: {},
  setStepOneData: () => {},
  stepTwoData: {},
  setStepTwoData: () => {},
  stepThreeData: {},
  setStepThreeData: () => {},
  stepFourData: {},
  setStepFourData: () => {},
  stepFiveData: {},
  setStepFiveData: () => {},
  stepSixData: {},
  setStepSixData: () => {},
  stepSevenData: {},
  setStepSevenData: () => {},
  stepEightData: {},
  setStepEightData: () => {},
  setZipcode: () => {}
});

export const OnboardingContextProvider = (props) => {
  const { children } = props;
  const {
    fetched,
    loading,
    error,
    user,
    test,
    stepOneData,
    stepTwoData,
    stepThreeData,
    setStepTwoData,
    setStepOneData,
    setStepThreeData,
    stepFourData,
    stepFiveData,
    stepSixData,
    stepSevenData,
    stepEightData,
    setStepFiveData,
    setStepSixData,
    setStepSevenData,
    setStepEightData,
    setStepFourData,
    setOnboarding,
    setZipcode,
  } = useOnboardingData();

  const providerValue = {
    fetched,
    loading,
    error,
    user,
    test,
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
    stepFiveData,
    stepSixData,
    stepSevenData,
    stepEightData,
    setStepFourData,
    setStepTwoData,
    setStepOneData,
    setStepThreeData,
    setStepFiveData,
    setStepSixData,
    setStepSevenData,
    setStepEightData,
    setOnboarding,
    setZipcode,
  };

  return <OnboardingContext.Provider value={providerValue}>{children}</OnboardingContext.Provider>;
};
