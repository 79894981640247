import StepThree from 'core/profile/ProfessionalInterestPage';
import React, { useReducer, useEffect } from 'react';

export const initialState = {
  isGroupMembersOpen: false,
  isCoach: null,
  coachResourcesLength: 0,
  coachResources: [],
  optionTitle: '',
  platformPreferences: [],
  personalInformation: [],
  workPlaceInformation: [],
  careerInformation: [],
  lessons: [],
  user: [],
  rubricOptions: [],
  enrollCourses: [],
  courses: [],
  resources: [],
  rubrics: [],
  rubricID: [],
  launchLink: [],
  user: [],
  progress: 0,
  groups: [],
  discussions: [],
  discussionUsers: [],
  coachFeedback: [],
  userComments: [],
  communityGroupDiscussions: [],
  groupMembers: [],
  courseDiscussions: [
    // {
    //     'name': 'Francine Maxwell',
    //     'time': new Date(),
    //     'bookmark': 'H&S Every Day',
    //     'comment': 'Praise is one of the most effective ways that you can use to decrease problem behavior and increase positive behavior.',
    //     'replies': [
    //         {
    //             'name': 'Johanne Spence',
    //             'time': new Date(),
    //             'comment': 'Nam porttitor est massa, ac dapibus mauris porttitor nec.',
    //         },
    //         {
    //             'name': 'Tricia Hodge',
    //             'time': new Date(),
    //             'comment': 'Nam porttitor est massa, ac dapibus mauris porttitor nec.',
    //         }
    //     ]
    // },
    // {
    //     'name': 'Francine Maxwell',
    //     'time': new Date(),
    //     'bookmark': 'H&S Every Day',
    //     'comment': 'Praise is one of the most effective ways that you can use to decrease problem behavior and increase positive behavior.',
    //     'replies': [
    //         {
    //             'name': 'Johanne Spence',
    //             'time': new Date(),
    //             'comment': 'Nam porttitor est massa, ac dapibus mauris porttitor nec.',
    //         },
    //         {
    //             'name': 'Tricia Hodge',
    //             'time': new Date(),
    //             'comment': 'Nam porttitor est massa, ac dapibus mauris porttitor nec.',
    //         }
    //     ]
    // },
  ],
  groupComments: [],
  courseReflections: [],
  resourceComments: [],
  sharedResourceComments: [],
  evaluationDetails: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'COACH_RESOURCES_LENGTH':
      return {
        ...state,
        coachResourcesLength: action.item
      };
    case 'POST_GROUP_MEMBERS':
      return {
        ...state,
        groupMembers: action.item
      };
    case 'EMPTY_GROUP_MEMBERS':
      return {
        ...state,
        groupMembers: []
      };
    case 'IS_COACH':
      return {
        ...state,
        isCoach: action.item
      };
    case 'POST_COACH_RESOURCES':
      return {
        ...state,
        coachResources: action.item
      };
    case 'POST_ENROLL_COURSES':
      return {
        ...state,
        enrollCourses: [...state.userComments, action.item]
      };
    case 'POST_USER_COMMENTS':
      return {
        ...state,
        userComments: [...state.userComments, action.item]
      };
    case 'EMPTY_USER_COMMENTS':
      return {
        ...state,
        userComments: []
      };
    case 'POST_COACH_FEEDBACK':
      return {
        ...state,
        coachFeedback: [...state.coachFeedback, action.item]
      };
    case 'EMPTY_COACH_FEEDBACK':
      return {
        ...state,
        coachFeedback: []
      };
    case 'ADD_COACH_FEEDBACK':
      return {
        ...state,
        coachFeedback: [...state.coachFeedback, action.item]
      };
    case 'ADD_COMMUNITY_GROUP_DISCUSSION':
      return {
        ...state,
        communityGroupDiscussions: [action.item, ...state.communityGroupDiscussions]
      };
    case 'ADD_COMMUNITY_GROUP_DISCUSSIONS':
      return {
        ...state,
        communityGroupDiscussions: action.item
      };
    case 'EMPTY_COMMUNITY_GROUP_DISCUSSIONS':
      return {
        ...state,
        communityGroupDiscussions: []
      };
    case 'POST_OPTION_TITLE':
      return {
        ...state,
        optionTitle: action.item
      };

    case 'DELETE_OPTION_TITLE':
      return {
        ...state,
        optionTitle: ''
      };
    case 'ADD_EVALUATION_DETAILS':
      return {
        ...state,
        evaluationDetails: [...state.evaluationDetails, action.item]
      };
    case 'FETCH_RESOURCE_COMMENTS':
      return {
        ...state,
        resourceComments: [...state.resourceComments, action.item]
      };
    case 'FETCH_SHARED_RESOURCE_COMMENTS':
      return {
        ...state,
        sharedResourceComments: [...state.sharedResourceComments, action.item]
      };
    case 'UPDATE_COURSE_REFLECTIONS':
      let newCourseReflections = [];
      for (let i = 0; i < state.courseReflections.length; i++) {
        let courseReflection = state.courseReflections[i];

        if (i === action.id) {
          courseReflection = action.item;
        }

        newCourseReflections.push(courseReflection);
      }
      return {
        ...state,
        courseReflections: newCourseReflections
      };
    case 'REMOVE_REFLECTION':
      const j = state.courseReflections?.findIndex((reflection, index) => index === action.index);
      let newReflections = [...state.courseReflections];
      if (j >= 0) {
        newReflections.splice(j, 1);
      } else {
        console.warn(`Can't remove group (index: ${action.index} as it is not in reflections!`);
      }
      return {
        ...state,
        courseReflections: newReflections
      };
    case 'POST_COURSE_REFLECTIONS':
      return {
        ...state,
        courseReflections: action.item
      };
    case 'POST_COURSE_REFLECTION':
      return {
        ...state,
        courseReflections: [...state.courseReflections, action.item]
      };
    case 'POST_COURSE_DISCUSSIONS':
      return {
        ...state,
        courseDiscussions: [...state.courseDiscussions, action.item]
      };
    case 'EMPTY_COURSE_DISCUSSIONS':
      return {
        ...state,
        courseDiscussions: []
      };
    case 'POST_COURSE_DISCUSSION_REPLY':
      for (let i = 0; i < state.courseDiscussions.length; i++) {
        let courseDiscussion = state.courseDiscussions[i];

        if (i === action.index) {
          courseDiscussion.posts.push(action.item);
        }
      }
    case 'FETCH_DISCUSSION_USERS':
      return {
        ...state,
        discussionUsers: [...state.discussionUsers, action.item]
      };
    case 'EMPTY_DISCUSSION_USERS':
      return {
        ...state,
        discussionUsers: []
      };
    case 'POST_DISCUSSION_USER_REPLY':
      for (let i = 0; i < state.discussionUsers.length; i++) {
        let courseDiscussion = state.discussionUsers[i];

        if (i === action.index) {
          discussionUsers.posts.push(action.item);
        }
      }
    case 'POST_REPLY':
      for (let i = 0; i < state.resourceComments[0]?.resourceComments.length; i++) {
        let resourceComment = state.resourceComments[0]?.resourceComments[i];

        if (i === action.index) {
          resourceComment.replies.push(action.item);
        }
      }
    case 'POST_SHARED_REPLY':
      for (let i = 0; i < state.sharedResourceComments[0]?.sharedResourceComments.length; i++) {
        let sharedResourceComment = state.sharedResourceComments[0]?.sharedResourceComments[i];

        if (i === action.index) {
          sharedResourceComment.replies.push(action.item);
        }
      }
    case 'FETCH_GROUP_COMMENTS':
      return {
        ...state,
        groupComments: [...state.groupComments, action.item]
      };
    case 'POST_GROUP_REPLY':
      for (let i = 0; i < state.groupComments[0]?.groupComments.length; i++) {
        let groupComment = state.groupComments[0]?.groupComments[i];

        if (i === action.index) {
          groupComment.discussionPosts.groupDiscussionPosts.push(action.item);
        }
      }
    case 'POST_DISCUSSIONS':
      return {
        ...state,
        discussions: [...state.discussions, action.item]
      };
    case 'POST_GROUPS':
      return {
        ...state,
        groups: [...state.groups, action.item]
      };
    case 'REMOVE_GROUP':
      const i = state.groups.findIndex((group, index) => index === action.index);
      let newGroups = [...state.groups];
      if (i >= 0) {
        newGroups.splice(i, 1);
      } else {
        console.warn(`Can't remove group (index: ${action.index} as it is not in groups!`);
      }
      return {
        ...state,
        groups: newGroups
      };
    case 'REMOVE_PROGRESS':
      return {
        ...state,
        progress: 0
      };
    case 'FETCH_PROGRESS':
      return {
        ...state,
        progress: action.item
      };
    case 'FETCH_USER':
      return {
        ...state,
        user: [action.item]
      };
    case 'POST_LAUNCHLINK':
      return {
        ...state,
        launchLink: [action.payload]
      };
    case 'REMOVE_LAUNCHLINK':
      return {
        ...state,
        launchLink: []
      };
    case 'FETCH_RUBRIC_ID':
      return {
        ...state,
        rubricID: [...state.rubricID, action.item]
      };
    case 'FETCH_RUBRICS':
      return {
        ...state,
        rubrics: [...state.rubrics, action.item]
      };
    case 'FETCH_RESOURCES':
      return {
        ...state,
        resources: [...state.resources, action.item]
      };
    case 'REMOVE_FROM_RESOURCES':
      const index = state.resources[0]?.resources.userContent.videos.findIndex(
        (video) => video.assetID === action.assetID
      );
      let newResources = [...state.resources];

      if (index >= 0) {
        newResources[0]?.resources.userContent.videos.splice(index, 1);
      } else {
        console.warn(`Can't remove item (id: ${action.assetID} as it is not in resources!`);
      }
      return {
        ...state,
        resources: newResources
      };
    case 'FETCH_COURSES':
      return {
        ...state,
        courses: [...state.courses, action.item]
      };
    case 'FETCH_GROUPS':
      return {
        ...state,
        groups: [...state.courses, action.item.groups]
      };
    case 'REMOVE_COURSES':
      return {
        ...state,
        courses: []
      };
    case 'POST_RUBRIC':
      return {
        ...state,
        rubricOptions: [action.payload]
      };
    case 'REMOVE_RUBRIC':
      return {
        ...state,
        rubricOptions: []
      };
    case 'FETCH_LESSONS':
      return {
        ...state,
        lessons: [...state.lessons, action.payload]
      };
    case 'UPDATE_PLATFORM_PREFERENCES':
      return {
        //whatever the state was
        ...state,
        platformPreferences: [...state.platformPreferences, action.item]
      };
    case 'REMOVE_GROUP_COMMENTS':
      return {
        ...state,
        groupComments: []
      };
    case 'OPEN_GROUP_MEMBERS':
      return {
        ...state,
        isGroupMembersOpen: true
      }
    case 'CLOSE_GROUP_MEMBERS':
      return {
        ...state,
        isGroupMembersOpen: false
      }
    default:
      return state;
  }
};

export default reducer;
