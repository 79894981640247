import Amplify, { Auth } from 'aws-amplify';
import { postRegistration } from 'services/http';
import { successResponse, errorResponse } from 'services/response-helper';

const {
  RAZZLE_API_HOST,
  RAZZLE_API_TOKEN,
  RAZZLE_COGNITO_REGION,
  RAZZLE_COGNITO_POOL,
  RAZZLE_COGNITO_CLIENT,
  RAZZLE_SIGN_IN_REDIRECT
} = window.env;

Amplify.configure({
  Auth: {
    region: RAZZLE_COGNITO_REGION,
    userPoolId: RAZZLE_COGNITO_POOL,
    userPoolWebClientId: RAZZLE_COGNITO_CLIENT,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false
  }
});

export async function currentAuthenticatedUser() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.error(error);
  }
}

export async function signUp({ userName, password, firstName, lastName, email, phone, dob}) {
  try {
    const body = {
      earlyWinsUserID: '',
      userName,
      password,
      primaryEmailAddress: email,
      mobileNumber: `+1${phone.replace(/\D/g, '')}`,
      dob: dob.toISOString().substring(0, 10),
      firstName,
      lastName
    };

    let response = await postRegistration(`${RAZZLE_API_HOST}/signUpUser`, body, RAZZLE_API_TOKEN);

    if (response.success === false) {
      return errorResponse('The user name already exists. Please try again.');
    } else {
      return response;
    }
  } catch (error) {
    return errorResponse('The user name already exists. Please try again.');
  }
}

export async function confirmSignUp(username, code) {
  try {
    const response = await Auth.confirmSignUp(username, code);
    if (response === 'SUCCESS') {
      return successResponse();
    }
    return errorResponse('Sign in confirmation failed.');
  } catch (error) {
    return errorResponse(error.message);
  }
}

export async function resendConfirmationCode(username) {
  try {
    await Auth.resendSignUp(username);
  } catch (err) {
    console.log('error resending code: ', err);
  }
}

export async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    return successResponse({ user, signInRedirect: RAZZLE_SIGN_IN_REDIRECT });
  } catch (err) {
    return errorResponse(err.message);
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    localStorage.clear();
    return successResponse();
  } catch (error) {
    errorResponse(error.message);
  }
}

export async function forgotPassword(username) {
  try {
    const response = await Auth.forgotPassword(username);
    const destination = response.CodeDeliveryDetails.Destination;
    return successResponse({ destination });
  } catch (error) {
    return errorResponse(error.message);
  }
}

export async function forgotPasswordSubmit(username, code, newPassword) {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    return successResponse();
  } catch (error) {
    return errorResponse(error.message);
  }
}