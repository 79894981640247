import ProximaWoff2 from "services/font/Proxima-Nova-Regular.woff2";
import Cabin from "services/font/Cabin.ttf";

export const proximaWoff2 = {
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Proxima Nova'),
    url(${ProximaWoff2}) format('woff2')
  `,
  // unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};