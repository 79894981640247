export const stateOptions = [
  { value: '', label: ' ' },
  {
    label: 'Alabama',
    value: 'AL'
  },
  {
    label: 'Alaska',
    value: 'AK'
  },
  {
    label: 'American Samoa',
    value: 'AS'
  },
  {
    label: 'Arizona',
    value: 'AZ'
  },
  {
    label: 'Arkansas',
    value: 'AR'
  },
  {
    label: 'California',
    value: 'CA'
  },
  {
    label: 'Colorado',
    value: 'CO'
  },
  {
    label: 'Connecticut',
    value: 'CT'
  },
  {
    label: 'Delaware',
    value: 'DE'
  },
  {
    label: 'District Of Columbia',
    value: 'DC'
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    label: 'Florida',
    value: 'FL'
  },
  {
    label: 'Georgia',
    value: 'GA'
  },
  {
    label: 'Guam',
    value: 'GU'
  },
  {
    label: 'Hawaii',
    value: 'HI'
  },
  {
    label: 'Idaho',
    value: 'ID'
  },
  {
    label: 'Illinois',
    value: 'IL'
  },
  {
    label: 'Indiana',
    value: 'IN'
  },
  {
    label: 'Iowa',
    value: 'IA'
  },
  {
    label: 'Kansas',
    value: 'KS'
  },
  {
    label: 'Kentucky',
    value: 'KY'
  },
  {
    label: 'Louisiana',
    value: 'LA'
  },
  {
    label: 'Maine',
    value: 'ME'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Maryland',
    value: 'MD'
  },
  {
    label: 'Massachusetts',
    value: 'MA'
  },
  {
    label: 'Michigan',
    value: 'MI'
  },
  {
    label: 'Minnesota',
    value: 'MN'
  },
  {
    label: 'Mississippi',
    value: 'MS'
  },
  {
    label: 'Missouri',
    value: 'MO'
  },
  {
    label: 'Montana',
    value: 'MT'
  },
  {
    label: 'Nebraska',
    value: 'NE'
  },
  {
    label: 'Nevada',
    value: 'NV'
  },
  {
    label: 'New Hampshire',
    value: 'NH'
  },
  {
    label: 'New Jersey',
    value: 'NJ'
  },
  {
    label: 'New Mexico',
    value: 'NM'
  },
  {
    label: 'New York',
    value: 'NY'
  },
  {
    label: 'North Carolina',
    value: 'NC'
  },
  {
    label: 'North Dakota',
    value: 'ND'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    label: 'Ohio',
    value: 'OH'
  },
  {
    label: 'Oklahoma',
    value: 'OK'
  },
  {
    label: 'Oregon',
    value: 'OR'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Pennsylvania',
    value: 'PA'
  },
  {
    label: 'Puerto Rico',
    value: 'PR'
  },
  {
    label: 'Rhode Island',
    value: 'RI'
  },
  {
    label: 'South Carolina',
    value: 'SC'
  },
  {
    label: 'South Dakota',
    value: 'SD'
  },
  {
    label: 'Tennessee',
    value: 'TN'
  },
  {
    label: 'Texas',
    value: 'TX'
  },
  {
    label: 'Utah',
    value: 'UT'
  },
  {
    label: 'Vermont',
    value: 'VT'
  },
  {
    label: 'Virgin Islands',
    value: 'VI'
  },
  {
    label: 'Virginia',
    value: 'VA'
  },
  {
    label: 'Washington',
    value: 'WA'
  },
  {
    label: 'West Virginia',
    value: 'WV'
  },
  {
    label: 'Wisconsin',
    value: 'WI'
  },
  {
    label: 'Wyoming',
    value: 'WY'
  }
];

export const stateEnum = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const countryOptions = [
  { label: 'American Samoa', value: 'AS' },
  { label: 'Australia', value: 'AU' },
  { label: 'Canada', value: 'CA' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' }
];

export const countryEnum = {
  CA: 'Canada',
  US: 'United States'
};

export const genderOptions = [
  { value: '', label: ' ' },
  { value: '0', label: 'Female' },
  { value: '1', label: 'Male' },
  { value: '2', label: 'Non-binary' },
  { value: '3', label: 'Other' }
];

export const genderEnum = {
  0: 'Female',
  1: 'Male',
  2: 'Non-binary',
  3: 'Other'
};

export const focusOptions = [
  { value: '', label: 'Interactions with children' },
  { value: '1', label: 'Learning activities' },
  { value: '2', label: 'Dual language learners' },
  { value: '3', label: 'Health and Safety' },
  { value: '4', label: 'Family Engagement' },
  { value: '5', label: 'Child observation and Assessment' },
  { value: '6', label: 'Curriculum' },
  { value: '7', label: 'Family culture' },
  { value: '8', label: 'Learning enviroments' },
  { value: '9', label: 'Leadership' }
];

export const raceOptions = [
  { value: '', label: ' ' },
  { value: '1', label: 'White/Caucasian' },
  { value: '2', label: 'Asian' },
  { value: '3', label: 'Black or African-American' },
  { value: '4', label: 'Native Hawaiian or Pacific Islander' },
  { value: '5', label: 'American Indian or Alaska Native' },
  { value: '6', label: 'Prefer not to specify' }
];

export const ethnicOptions = [
  { value: '', label: ' ' },
  { value: '1', label: 'Hispanic/Latino' },
  { value: '2', label: 'Not Hispanic/Latino' },
  { value: '3', label: 'Prefer not to specify' }
];

export const raceEnum = {
  0: 'Cuban',
  1: 'Mexican',
  2: 'Puerto Rican',
  3: 'South American',
  4: 'Other Hispanic/Latino',
  5: 'Asian Indian',
  6: 'Cambodian',
  7: 'Chinese',
  8: 'Filipino',
  9: 'Japanese',
  10: 'Korean',
  11: 'Loatian',
  12: 'Malaysian',
  13: 'Pakistani',
  14: 'Thai',
  15: 'Vietnamese',
  16: 'Other Asian',
  17: 'Black or African-American',
  18: 'Bi-racial or Multi-racial',
  19: 'Native American or Alaskan Native',
  20: 'Native Hawaiin',
  21: 'Samoan',
  22: 'Guamanian',
  23: 'Other Pacific Islander',
  24: 'White/Caucasian',
  25: 'Other'
};

export const languageOptions = [
  // { value: "", label: " " },
  { value: '0', label: 'English' },
  { value: '1', label: 'Spanish' },
  { value: '2', label: 'Cantonese' },
  { value: '3', label: 'Mandarin' },
  { value: '4', label: 'Tagalog' },
  { value: '5', label: 'Vietnamese' },
  { value: '6', label: 'Korean' }
  // { value: "7", label: "Other: (specify)" },
];

export const motivationOptions = [
  { value: '0', label: "Let's go!!" },
  { value: '1', label: 'Pretty excited.' },
  { value: '2', label: "We'll see." },
  { value: '3', label: 'Not really pumped.' },
  { value: '4', label: 'Meh.' }
];

export const languageEnum = {
  0: 'American Sign Language',
  1: 'Arabic',
  2: 'Armenian',
  3: 'Cantonese',
  4: 'English',
  5: 'Farsi',
  6: 'French',
  7: 'Hmong',
  8: 'Japanese',
  9: 'Korean',
  10: 'Mandarin',
  11: 'Russian',
  12: 'Spanish',
  13: 'Tagalog',
  14: 'Vietnamese'
};

export const maritalStatusOptions = [
  { value: '', label: '' },
  { value: '0', label: 'Single/Widowed/Divorced' },
  { value: '1', label: 'Married or in a civil union' }
];

export const maritalStatusEnum = {
  0: 'Single/Widowed/Divorced',
  1: 'Married or in a civil union'
};

export const educationLevelOptions = [
  { value: '', label: ' ' },
  { value: '0', label: 'Less than High School Diploma or equivalent' },
  { value: '1', label: 'High School Diploma/GED' },
  { value: '2', label: 'CDA (Child Development Assoc. Credential) ' },
  { value: '3', label: 'Associate’s Degree' },
  { value: '4', label: 'Bachelor’s Degree' },
  { value: '5', label: 'Master’s Degree' },
  { value: '6', label: 'Doctorate' }
];

export const educationLevelEnum = {
  0: 'No high school diploma/No GED',
  1: 'High School Diploma/GED',
  2: 'Some College',
  3: "Associate's Degree",
  4: "Bachelor's Degree",
  5: "Master's Degree",
  6: 'Doctorate Degree',
  7: 'Foreign Degree'
};

export const caChildDevelopmentPermitLevelsOptions = [
  { value: '', label: '' },
  { value: '0', label: 'Assistant' },
  { value: '1', label: 'Associate Teacher' },
  { value: '2', label: 'Teacher' },
  { value: '3', label: 'Master Teacher' },
  { value: '4', label: 'Site Supervisor' },
  { value: '5', label: 'Program Director' },
  { value: '6', label: "Regular Children's Center Instruction Permit" },
  { value: '7', label: "Children's Center Instruction/Supervision" },
  { value: '8', label: 'Child Development Programs Instruction/Supervision' },
  { value: '9', label: 'Child Development Associate (CDA)' },
  { value: '10', label: 'Certificate of Clearance' }
];

export const caChildDevelopmentPermitLevelsEnum = {
  0: 'Assistant',
  1: 'Associate Teacher',
  2: 'Teacher',
  3: 'Master Teacher',
  4: 'Site Supervisor',
  5: 'Program Director',
  6: "Regular Children's Center Instruction Permit",
  7: "Children's Center Instruction/Supervision",
  8: 'Child Development Programs Instruction/Supervision',
  9: 'Child Development Associate (CDA)',
  10: 'Certificate of Clearance'
};

export const eceEducationLevelsOptions = [
  { value: '', label: '' },
  { value: '0', label: 'Less tha 12 ECE/CD units' },
  { value: '1', label: '12 ECE/CD units' },
  { value: '2', label: '24 ECE/CD units' },
  { value: '3', label: '24 ECE/CD UNITS +16 units GE' },
  { value: '4', label: 'Degree in ECE/CD' },
  { value: '5', label: '60 Degree Applicable Units,including 24 ECE/CD' },
  { value: '6', label: '48 + Units of ECE/CD' }
];

export const eceEducationLevelsEnum = {
  0: 'Less tha 12 ECE/CD units',
  1: '12 ECE/CD units',
  2: '24 ECE/CD units',
  3: '24 ECE/CD UNITS +16 units GE',
  4: 'Degree in ECE/CD',
  5: '60 Degree Applicable Units,including 24 ECE/CD',
  6: '48 + Units of ECE/CD'
};

export const primaryGroupsServedOptions = [
  { value: '', label: '' },
  { value: '0', label: 'Infant (Birth to 18 Months)' },
  { value: '1', label: 'Toddler (19 Months up to 36 Months)' },
  { value: '2', label: 'Preschool (3 to 5 Years)' },
  { value: '3', label: 'Transitional Kindergarten (4-5)' },
  { value: '4', label: 'School-Age/Elementary (Kindergarden - 6th)' },
  { value: '5', label: 'Out-of-School-Time/After School' },
  { value: '6', label: 'Mixed Ages of Children' },
  { value: '7', label: 'Adults in Field' },
  { value: '8', label: 'Parents/Families' }
];

export const primaryGroupsServedEnum = {
  0: 'Infant (Birth to 18 Months)',
  1: 'Toddler (18 months to 36 months)',
  2: 'Preschool (3 years to 5 years)',
  3: 'Transitional Kindergarten (TK)',
  // 4: "School-Age/Elementary (Kindergarden - 6th)",
  4: 'After-School / Out-of-School Time (K-6)',
  // 6: "Mixed Ages of Children",
  5: 'Families',
  6: 'Early Childhood Teachers/Providers'
};

export const selfReportedTeacherCredentialOptions = [
  { value: '', label: '' },
  { value: '0', label: 'Administrative Services' },
  { value: '1', label: 'Bilingual Specialist' },
  { value: '2', label: 'Career Technical Education Teaching Credential' },
  { value: '3', label: 'Clinical/Rehab Services' },
  { value: '4', label: 'Early Childhood Special Education' },
  {
    value: '5',
    label: 'Education Specialist (Disabilities and Special Needs)'
  },
  { value: '6', label: 'Library Media Services' },
  { value: '7', label: 'Multiple Subject' },
  { value: '8', label: 'Other Health Services' },
  { value: '9', label: 'Pupil Personnel Services' },
  { value: '10', label: 'Reading/Language Arts' },
  { value: '11', label: 'Reading Specialist' },
  { value: '12', label: 'Reading Certificate' },
  { value: '13', label: 'School Nurse Services' },
  { value: '14', label: 'Single Subject' },
  { value: '15', label: 'Special Needs' },
  { value: '16', label: 'Specialist Instruction' },
  { value: '17', label: 'Speech-Language Pathology' },
  { value: '18', label: 'Other' }
];
export const selfReportedTeacherCredentialEnum = {
  0: 'Administrative Services',
  1: 'Bilingual Specialist',
  2: 'Career Technical Education Teaching Credential',
  3: 'Clinical/Rehab Services',
  4: 'Early Childhood Special Education',
  5: 'Education Specialist (Disabilities and Special Needs)',
  6: 'Library Media Services',
  7: 'Multiple Subject',
  8: 'Other Health Services',
  9: 'Pupil Personnel Services',
  10: 'Reading/Language Arts',
  11: 'Reading Specialist',
  12: 'Reading Certificate',
  13: 'School Nurse Services',
  14: 'Single Subject',
  15: 'Special Needs',
  16: 'Specialist Instruction',
  17: 'Speech-Language Pathology',
  18: 'Other'
};

export const notificationPreferences = [
  { value: '', label: '' },
  { value: '0', label: 'Email' },
  { value: '1', label: 'Text Message' }
];

export const workPlaceOptions = [
  { value: '', label: ' ' },
  { value: '0', label: 'FFN (unlicensed)' },
  { value: '1', label: 'Family Child Care Home (licensed)' },
  { value: '3', label: 'Center' },
  { value: '4', label: 'School-based' },
  { value: '6', label: 'Other: specify' }
];

export const ageGroupOptions = [
  'Under 2 years',
  '2 years',
  '3 years',
  '4 years',
  '5 years',
  'over 5 years'
];

export const currentRoleOptions = [
  "Home-based Teacher/Caregiver",
  "Center-based Teacher/Caregiver",
  "Center/Program Administration",
  "Professional Support (e.g. Coach&comma; Trainer)",
  "Home Visitor",
  "Child Care Resource and Referral",
  "Other"
];

export const currentSalaryOptions = [
  { value: '', label: ' ' },
  { value: '0', label: 'Less than $10k' },
  { value: '1', label: '$10-20K' },
  { value: '2', label: '$20-30K' },
  { value: '3', label: '$30-40K' },
  { value: '4', label: '$40-50K' },
  { value: '5', label: '$50K+' }
];

export const incomeOptions = [
  { value: '', label: ' ' },
  { value: '0', label: 'Less than $10k' },
  { value: '1', label: '$10-20K' },
  { value: '2', label: '$20-30K' },
  { value: '3', label: '$30-40K' },
  { value: '4', label: '$40-50K' },
  { value: '5', label: '$50K+' }
];

export const lengthOfTimeInFieldOptions = [
  // { value: "", label: " " },
  { value: '0', label: '0-1 year' },
  { value: '1', label: '1-2 years' },
  { value: '2', label: '2-5 years' },
  { value: '3', label: '5-10 years' },
  { value: '4', label: '10+ years' }
];

export const testOptions = [
  { value: '', label: ' ' },
  { value: '0', label: 'Home-based Provider' },
  { value: '1', label: 'PS Teacher' },
  { value: '2', label: 'IT Teacher' },
  { value: '3', label: 'Director' },
  { value: '4', label: 'Asst Director' },
  { value: '5', label: 'Owner' },
  { value: '6', label: 'Coach' },
  { value: '7', label: 'other' }
];

export const highestLevelOfECE = [
  { value: '', label: ' ' },
  { value: '0', label: 'Child Development Assistant Permit' },
  { value: '1', label: 'Child Development Associate Teacher Permit' },
  { value: '2', label: 'Child Development Teacher Permit' },
  { value: '3', label: 'Child Development Master Teacher Permit' },
  { value: '4', label: 'Child Development Site Supervisor Permit' },
  { value: '5', label: 'Child Development Program Director Permit' },
  { value: '6', label: 'Associate’s in Early Childhood' },
  { value: '7', label: 'Bachelor’s in Early Childhood' },
  { value: '8', label: 'More than Bachelor’s' },
  { value: '9', label: 'Other' }
];



export const data = [
  {
    'item': 1,
    
    'ratings': [
      {
        name: 'Dummy Data',
        star: 4
      }, 
      {
        name: 'Dummy Data',
        star: 4
      }, 
      {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      },
      {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }, {
        name: 'Dummy Data',
        star: 4
      }
    ]
  },

];