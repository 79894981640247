import React, { lazy, Suspense } from 'react';
import StateProvider from '../providers/StateProvider';
import reducer, { initialState } from '../reducer';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import '../sass/main.scss';
import { OnboardingContextProvider } from '../providers/onboarding-provider';
import { useLayoutSize } from 'hooks/useLayoutSize';
import { mapRoute } from 'services/routing';
import { useSignedInUser } from 'hooks/useSignedInUser';
import { useCourses } from 'hooks/useCourses';
import Spinner from 'common/Spinner';

const Layout = lazy(() => import('app/Layout'));
const LayoutWrapper = lazy(() => import('app/LayoutWrapper'));
const PageScaffold = lazy(() => import('app/PageScaffold'));
const LandingPage = lazy(() => import('core/landing/Landing'));
const SignInPage = lazy(() => import('core/account/SignInPage'));
const SignUpPage = lazy(() => import('core/account/SignUpPage'));
const ConfirmSignUpPage = lazy(() => import('core/account/ConfirmSignUpPage'));
const RecoverPasswordPage = lazy(() => import('core/account/RecoverPasswordPage'));
const ConfirmRecoverPasswordPage = lazy(() => import('core/account/ConfirmRecoverPasswordPage'));
const Onboarding = lazy(() => import('core/onboarding/Onboarding'));
const Home = lazy(() => import('core/home/Home'));
const Lessons = lazy(() => import('core/learning/Lessons'));
const Lesson = lazy(() => import('core/learning/Lesson'));
const Enroll = lazy(() => import('core/enroll/Enroll'));
const EnrollModule = lazy(() => import('core/enroll/EnrollModule'));
const Notifications = lazy(() => import('core/notifications/Notifications'));
const Resources = lazy(() => import('core/resources/Resources'));
const PinnedLibrary = lazy(() => import('core/library/PinnedLibrary'));
const ReviewResourcesPage = lazy(() => import('core/resources/ReviewResourcesPage'));
const SharedReviewResourcesPage = lazy(() => import('core/resources/SharedReviewResourcesPage'));
const BrowsePage = lazy(() => import('core/browse/BrowsePage'));
const Community = lazy(() => import('core/community/Community'));
const CommunityFull = lazy(() => import('core/communityNew/CommunityFull'));
const CommunitySuggestedFull = lazy(() => import('core/communityNew/CommunitySuggestedFull'));
const Group = lazy(() => import('core/community/Group'));
const CommunityGroup = lazy(() => import('core/communityNew/CommunityGroup'));
const Coaching = lazy(() => import('core/coaching/Coaching'));
// const CoachingResourcesPage = lazy(() => import('core/coaching/Student/StudentResource'));
const CoachingResourcesPage = lazy(() => import('core/coaching/Feedback'));
const Evaluations = lazy(() => import('core/coaching/Evaluations/EvaluationsFeedback'));
const Dashboard = lazy(() => import('core/dashboard/Dashboard'));
const RecentActivitiesPage = lazy(() => import('core/recent/RecentActivitiesPage'));
const CategoryListPage = lazy(() => import('core/browse/CategoryListPage'));
const ModuleListPage = lazy(() => import('core/browse/ModuleListPage'));
const CourseListPage = lazy(() => import('core/browse/CourseListPage'));
const CategoryBrowsePage = lazy(() => import('core/browse/CategoryBrowsePage'));
const TrainingPage = lazy(() => import('core/browse/TrainingPage'));
const ProfilePage = lazy(() => import('core/profile/ProfilePage'));
const SettingsPage = lazy(() => import('core/profile/SettingsPage'));
const CareerInformationPage = lazy(() => import('core/profile/CareerInformationPage'));
const DemographicInformationPage = lazy(() => import('core/profile/DemographicInformationPage'));
const ProfessionalInterestPage = lazy(() => import('core/profile/ProfessionalInterestPage'));
const PlatformPreferencesPage = lazy(() => import('core/profile/PlatformPreferencesPage'));
const ProfileInformationPage = lazy(() => import('core/profile/ProfileInformationPage'));
const WorkplaceInformationPage = lazy(() => import('core/profile/WorkplaceInformationPage'));
const PictureUploadPage = lazy(() => import('core/profile/PictureUploadPage'));
const CourseDetailPage = lazy(() => import('core/course-detail/CourseDetailPage'));
const NotFound = lazy(() => import('core/NotFound'));
const InterestAndGoals = lazy(() => import('core/profile/InterestAndGoals'));
const WorkAndBackground = lazy(() => import('core/profile/WorkAndBackground'));


export default function App() {
  const location = useLocation();
  const layoutSize = useLayoutSize();
  const page = mapRoute(location.pathname);

  const { user, loading } = useSignedInUser();
  const { enrolledModules, loadingM } = useCourses();

  if (location.pathname !== '/onboarding') {
    if (user && user.userProfile) {
      if (user?.userProfile['CSPP'] !== 'finished' || !user?.userProfile) {
        window.location.href = '/onboarding';
      }
    }
  }
  if (location.pathname === '/onboarding') {
    if (user && user.userProfile) {
      if (user?.userProfile['CSPP'] === 'finished') {
        if (!loadingM) {
          if (enrolledModules.length > 0) {
            window.location.href = '/home';
          } else {
            window.location.href = '/enroll';
          }
        } else {
          return null;
        }
      }
    }
  }
  if (location.pathname === '/home') {
    if (user && user.userProfile) {
      if (user?.userProfile['CSPP'] !== 'finished' || !user?.userProfile['CSPP']) {
        window.location.href = '/onboarding';
      }
    }
    if (loading) {
      return null;
    }
  }

  if (location.pathname !== '/' && location.pathname.endsWith('/')) {
    window.location.assign(location.pathname.slice(0, -1));
  }

  window.addEventListener('error', (e) => {
    // prompt user to confirm refresh
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
    }
  });

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <OnboardingContextProvider>
        <Suspense fallback={<Spinner loading={true} />}>
          <Layout layoutSize={layoutSize} page={page}>
            <LayoutWrapper>
              <Switch>
                <Route exact path="/">
                  <LandingPage />
                </Route>
                <Route exact path="/signin">
                  <SignInPage />
                </Route>
                <Route exact path="/signup">
                  <SignUpPage />
                </Route>
                <Route exact path="/signup/confirm">
                  <ConfirmSignUpPage />
                </Route>
                <Route exact path="/recover-password">
                  <RecoverPasswordPage />
                </Route>
                <Route exact path="/recover-password/confirm">
                  <ConfirmRecoverPasswordPage />
                </Route>
                <Route exact path="/onboarding">
                  <Onboarding layoutSize={layoutSize} />
                </Route>
                <Route exact path="/profile">
                  <ProfilePage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings">
                  <SettingsPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/preferences">
                  <PlatformPreferencesPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/general">
                  <ProfileInformationPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/demographics">
                  <DemographicInformationPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/professionalInterest">
                  <ProfessionalInterestPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/workplace">
                  <WorkplaceInformationPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/career">
                  <CareerInformationPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/interests-and-goals">
                  <InterestAndGoals layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/work-and-background">
                  <WorkAndBackground layoutSize={layoutSize} />
                </Route>
                <Route exact path="/settings/picture">
                  <PictureUploadPage layoutSize={layoutSize} />
                </Route>

                <Route exact path="/recent">
                  <RecentActivitiesPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/recent/:id">
                  <CourseDetailPage layoutSize={layoutSize} />
                </Route>

                <Route exact path="/browse">
                  <BrowsePage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/courses">
                  <CourseListPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/courses/:id">
                  <CourseDetailPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/modules">
                  <ModuleListPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/categories">
                  <CategoryListPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/categories/:id">
                  <CategoryBrowsePage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/training">
                  <TrainingPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/home">
                  <Home layoutSize={layoutSize} />
                </Route>
                <Route exact path="/dashboard">
                  <Dashboard layoutSize={layoutSize} />
                </Route>
                <Route exact path="/learning/:id">
                  <Lessons layoutSize={layoutSize} />
                </Route>
                <Route exact path="/learning/:id/:lesson">
                  <Lesson layoutSize={layoutSize} />
                </Route>
                <Route exact path="/enroll">
                  <Enroll layoutSize={layoutSize} />
                </Route>
                <Route exact path="/enroll/:id">
                  <EnrollModule layoutSize={layoutSize} />
                </Route>
                <Route exact path="/enroll/:id/:lesson">
                  <Lesson layoutSize={layoutSize} />
                </Route>
                <Route exact path="/notifications">
                  <Notifications layoutSize={layoutSize} />
                </Route>
                <Route exact path="/library">
                  <Resources layoutSize={layoutSize} />
                </Route>
                <Route exact path="/library/pinned">
                  <PinnedLibrary layoutSize={layoutSize} />
                </Route>
                <Route exact path="/library/:videoId">
                  <ReviewResourcesPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/shared-library/:videoId">
                  <SharedReviewResourcesPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/community">
                  <Community layoutSize={layoutSize} />
                </Route>
                <Route exact path="/community/groups">
                  <CommunityFull layoutSize={layoutSize} />
                </Route>
                <Route exact path="/community/suggested-groups">
                  <CommunitySuggestedFull layoutSize={layoutSize} />
                </Route>
                {/* <Route exact path="/community/:id">
                  <Group layoutSize={layoutSize} />
                </Route> */}
                <Route exact path="/community/:id">
                  <CommunityGroup layoutSize={layoutSize} />
                </Route>
                <Route exact path="/coaching">
                  <Coaching layoutSize={layoutSize} />
                </Route>
                <Route exact path="/coaching/:videoId">
                  <CoachingResourcesPage layoutSize={layoutSize} />
                </Route>
                <Route exact path="/coaching/evaluations/:videoId">
                  <Evaluations layoutSize={layoutSize} />
                </Route>
                <Route exact path="/progress">
                  <PageScaffold title="My Progress" />
                </Route>
                <Route component={NotFound} />
              </Switch>
            </LayoutWrapper>
          </Layout>
        </Suspense>
      </OnboardingContextProvider>
    </StateProvider>
  );
}
