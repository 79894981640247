import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'app/AppContext';
import { get } from 'services/http';

const { RAZZLE_API_HOST } = window.env;

export function useCourses() {
  const context = useContext(AppContext);
  const [fetched, setFetched] = useState(false);
  const [loadingM, setLoadingM] = useState(false);
  const [error, setError] = useState(false);
  const [courses, setCourses] = useState(null);
  const [modules, setModules] = useState(null);
  const [enrolledModules, setEnrolledModules] = useState([]);

  useEffect(() => {
    async function hydrate() {
      setLoadingM(true);

      const url = `${RAZZLE_API_HOST}/getUserCourseOutline?afinaUserID=${context?.user?.ewId}`;
      const { success, data } = await get(url, context?.user?.accessToken);
      if (success) {
        setCourses(data?.results.courses);

        let arr = [];
        let enrolledArr = [];
        for (let i = 0; i < data.results.courses.length; i++) {
          let moduleList = data.results.courses[i].moduleList;

          for (let j = 0; j < moduleList.length; j++) {
            let module = moduleList[j];

            arr.push(module);

            if (module.isEnrolled === 1) {
              enrolledArr.push(module);
            }
          }
        }
        setModules(arr);
        setEnrolledModules(enrolledArr);
      } else {
        setError(true);
      }

      setFetched(true);
      setLoadingM(false);
    }

    if (context?.user?.accessToken && !loadingM && !fetched) {
      hydrate();
    }
  }, [loadingM, fetched, context]);

  return {
    fetched,
    loadingM,
    error,
    courses,
    modules,
    enrolledModules
  };
}
