import { useContext, useState } from 'react';
import { AppContext } from 'app/AppContext';
import { useSignedInUser } from 'hooks/useSignedInUser';

export const useOnboardingData = () => {
  // const { user } = useSignedInUser()
  const context = useContext(AppContext);
  const [args, setOnboarding] = useState({});
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [stepOneData, setStepOneData] = useState([]);
  const [stepTwoData, setStepTwoData] = useState([]);
  const [stepThreeData, setStepThreeData] = useState([]);
  const [stepFourData, setStepFourData] = useState('');
  const [stepFiveData, setStepFiveData] = useState([]);
  const [stepSixData, setStepSixData] = useState('');
  const [stepSevenData, setStepSevenData] = useState([]);
  const [stepEightData, setStepEightData] = useState('');
  const [zipcode, setZipcode] = useState('');

  return {
    fetched,
    loading,
    error,
    user,
    stepOneData,
    stepTwoData,
    stepThreeData,
    setStepTwoData,
    setStepOneData,
    setStepThreeData,
    setOnboarding,
    stepFourData,
    stepFiveData,
    stepSixData,
    stepSevenData,
    stepEightData,
    setStepFourData,
    setStepFiveData,
    setStepSixData,
    setStepSevenData,
    setStepEightData,
    setZipcode,
  };
};
