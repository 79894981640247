import React from 'react';
import { DatePicker } from '@material-ui/pickers';

export default function FieldDatePicker({ field, form, ...other }) {
  const { name, value } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const touchedField = touched[name];
  const currentError = errors[name];
  const hasError = Boolean(touchedField && currentError);

  const handleChange = (date) => {
    setFieldTouched(name, Boolean(date));
    setFieldValue(name, date, true);
  };

  return (
    <DatePicker
      variant="outlined"
      fullWidth
      name={name}
      value={value}
      helperText={hasError ? 'Required' : ''}
      error={hasError}
      onChange={handleChange}
      inputVariant="outlined"
      format="MM/dd/yyyy"
      disableFuture
      openTo="year"
      views={['year', 'month', 'date']}
      {...other}
    />
  );
}
