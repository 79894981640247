import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(2)
  },
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1100
  }
}));

export default function SubmitButtonForm() {
  const classes = useStyles();
  return (
    <Paper square className={classes.footer}>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" color="primary" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
