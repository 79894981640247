import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { proximaWoff2 } from 'services/font';

const colors = {
  primary: '#026097',
  secondary: '#469A1F',
  error: red.A400
};

const theme = createMuiTheme({
  typography: {
    fontFamily: proximaWoff2.fontFamily
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [proximaWoff2]
      }
    }
  },
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: colors.error
    },
    background: {
      // default: '#fff',
    }
  }
});

export default theme;
