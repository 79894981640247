import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'app/AppContext';
import { get } from 'services/http';

const { RAZZLE_API_HOST } = window.env;

export function useSignedInUser() {
  const context = useContext(AppContext);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function hydrate() {
      setLoading(true);

      const url = `${RAZZLE_API_HOST}/getUserResponse/${context?.user?.ewId}`;
      const { success, data } = await get(url, context?.user?.accessToken);

      if (success) {
        setUser(data?.results?.user);
      } else {
        setError(true);
      }

      setFetched(true);
      setLoading(false);
    }

    if (context?.user?.accessToken && !loading && !fetched) {
      hydrate();
    }
  }, [loading, fetched, context]);

  return {
    fetched,
    loading,
    error,
    user
  };
}
